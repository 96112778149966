import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Team from './Team'; // Import the Image component

const GitLabStats = () => {
  const [ownerStats, setOwnerStats] = useState([]);
  const personalAccessToken = 'glpat-K9WNvx9ibVnUALNRZX18';
  const projectId = '50451619';

  useEffect(() => {
    const predefinedUsers = ['colebrown2552', 'amorales159', 'adonaytesfaye', 'omarazm123', 'ryanjpark03'];
    const fetchData = async () => {
      try {
        const ownerStatsData = await Promise.all(
          predefinedUsers.map(async (username) => {
            let author = username;
  
            if (username === 'amorales159') {
                author = 'Alexandro Morales';
            }
            if (username === 'omarazm123') {
              author = 'omar';
            }
  
            const commitsUrl = `https://gitlab.com/api/v4/projects/${projectId}/repository/commits?author=${author}&all=true&per_page=100`;
            const commitsResponse = await axios.get(commitsUrl, {
              headers: { 'PRIVATE-TOKEN': personalAccessToken },
            });
            let commitsCount = commitsResponse.data.length;
            const issuesUrl = `https://gitlab.com/api/v4/projects/${projectId}/issues?author_username=${username}`;
            const issuesResponse = await axios.get(issuesUrl, {
              headers: { 'PRIVATE-TOKEN': personalAccessToken },
            });
            const issuesCount = issuesResponse.data.length;
  
            return { username, commitsCount, issuesCount };
          })
        );
  
        setOwnerStats(ownerStatsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []); // Include predefinedUsers in the dependency array
  

  const usersData = [
    {
        "name": "Cole Brown",
        "username": "colebrown2552",
        "bio": "I'm a junior CS student here at UT. I enjoy bouldering at Austin Bouldering Project for Texas Rock Climbing.",
        "role": "Frontend",
        "tests": 20
    },
    {
        "name": "Alex Morales",
        "username": "amorales159",
        "bio": " I am a third year CS student at the University of Texas at Austin. I enjoy learning about new pieces of software and volunteering whenever I'm able.",
        "role": "Backend and Database",
        "tests": 10
    },
    {
        "name": "Adonay Tesfaye",
        "username": "adonaytesfaye",
        "bio": "I'm a third year CS major at UT and I enjoy reading One Piece and playing basketball.",
        "role": "Frontend and Database",
        "tests": 6
    },
    {
        "name": "Omar Abouelazm",
        "username": "omarazm123",
        "bio": "I’m a junior at UT Austin majoring in Computer Science. I love to teach and enjoy boxing in my free time!",
        "role": "Frontend",
        "tests": 1
    },
    {
        "name": "Ryan Park",
        "username": "ryanjpark03",
        "bio": "I'm a junior in Computer Science with Polymathic Honors at the University of Texas at Austin. I love rock climbing, brain teasers, and board games when I can!",
        "role": "Backend and Database",
        "tests": 4
    }
  ];

  return (
        <div className="container mt-5 bios">
            <div className="row">
                {ownerStats.map((stat) => {
                    const user = usersData.find((user) => user.username === stat.username);
                    return (
                        <div key={stat.username} className="col-md-4 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-text dark-blue">{user ? user.name : 'N/A'}</h5>
                                    <Team stat={stat} />
                                    <p className="card-title text-center">{stat.username}</p>
                                    <p className="card-text">Commits: {stat.commitsCount}</p>
                                    <p className="card-text">Issues: {stat.issuesCount}</p>
                                    <p className="card-text">Role: {user ? user.role : 'N/A'}</p>
                                    <p className="card-text">{user ? user.bio : 'N/A'}</p>
                                    <p className="card-text">Unit Tests: {user.tests}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default GitLabStats;
