// CSS imports
import './assets/css/style.css';
import './assets/css/base.css';

// Library and framework imports
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Scatter, ScatterChart, Bar, BarChart, Rectangle } from 'recharts';
import { Chart as ChartJS, ArcElement, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

// Local component imports
import BarGraph from "../src/ProviderVisualizations/LocationBarGraph.js";
import SpikeGraph from "../src/ProviderVisualizations/FarmersSpikeGraph.js";

// Component definitions...
ChartJS.register(ArcElement, Legend);

export function Visuals_landing(){
  return (<div>
    <section className="visuals display-flex">
      <div className="grid-container display-flex flex-column">
        <div className="grid-100 mobile-grid-100 tablet-grid-100 bubble text-align-center">
          <h1>
            Visualizations
          </h1>
        </div>
        <div className="mt-3 bubble">
          <Link to="/our_visuals" className="btn btn-primary rounded-pill"> Our Visuals </Link>
          <Link to="/developer_visuals" className="btn btn-primary rounded-pill ms-3"> Developer Visuals </Link>
        </div>
      </div>
    </section>
  </div>);
}

export function Our_visuals( {disorder_data, treatment_data, location_data} ){
  const CustomTooltipV1 = ({ active, payload, label }) => {
    if ( active && payload && payload.length ) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`}</p>
          <p className="intro">{``}</p>
          <p className="desc">{`rating: ${payload[0].payload.rating}`}</p>
        </div>
      );
    }
    return null;
  };
  location_data.sort(function(a, b){return a.rating - b.rating}).reverse();
  const renderLineChart = (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart width={1200} height={400} data={location_data} margin={{top: 1}}>

        <Line type="monotone" dataKey="rating" stroke="#8884d8" />
        <CartesianGrid stroke="#ccc" vertical={false}/>
        <YAxis axisLine={false} tickLine={false} ticks={[0, 1, 2, 3, 4, 5]} label={{ value: "Rating", angle: -90, position: "insideLeft", dy: 100}}/>
        <XAxis dataKey="name" tick={false} label={{ value: "Location Name", position: "insideBottom"}}/>
        <Tooltip content={CustomTooltipV1}/>
      </LineChart>
    </ResponsiveContainer>
  );


  const CustomTooltipV2 = ({ active, payload, label }) => {
    if ( active && payload && payload.length ) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${payload[0].payload.name}`}</p>
          <pre className="desc">{`address:    ${payload[0].payload.address} \nlongitude: ${payload[0].payload.longitude}\nlatitude:   ${payload[0].payload.latitude}`}</pre>
        </div>
      );
    }
    return null;
  };
  const renderScatterChart = (
    <ResponsiveContainer width="100%" height={600}>
        <ScatterChart margin={{ top: 1, right: 20, bottom: 20, left: 20 }}>

          <Scatter name="Locations" data={location_data} fill="#11e7c9" shape="wye"/>
          <CartesianGrid hide={true}/>
          <Tooltip cursor={{ strokeDasharray: '3 3' }} content={CustomTooltipV2} position={{x:100, y:140}}/>
          <YAxis dataKey="latitude" type="number" name="latitude" domain={[30.15, 30.55]} allowDataOverflow={true} 
            label={{ value: "Latitude", angle: -90, position: "insideLeft"}}
            tick={{ fontSize: 10 }}/>
          <XAxis dataKey="longitude" type="number" name="longitude" domain={[-97.87, -97.7]} allowDataOverflow={true} 
            label={{ value: "Longitude", position: "insideBottom", offset: -10 }}
            tick={{ fontSize: 10 }}/>
        </ScatterChart>
    </ResponsiveContainer>
  );

  const renderBarChart = (
    <ResponsiveContainer width="100%" height={600}>
        <BarChart width={150} height={40} data={treatment_data
        .filter((item) => item.disorders_list)
        .map((item) => ({
            name: item.title.toLowerCase().split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" "),
            disorderCount: item.disorders_list.length,
        }))
        .filter((item) => item.disorderCount > 1)
        .slice(0, 50)} 
            margin={{ top: 1, right: 30, left : 20, bottom: 5, }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={false} label={{ value: "Treatment Name", position: "insideBottom"}}/>
            <YAxis label={{ value: "Number of Disorders Served", angle: -90, position: "insideLeft", dy: 100 }}/>
            <Tooltip />
            <Legend />
            <Bar dataKey="disorderCount" fill="#8884d8" activeBar={<Rectangle fill="gold" stroke="blue" />} />
            {console.log(treatment_data)}
        </BarChart>
    </ResponsiveContainer>
  );
  
  return (
    <div className="mt-3 container">
      <div className="visual 1">
        <pre />
        <pre />
        <h3 style={{ display: 'flex', justifyContent: 'center', paddingTop: '5%', paddingBottom: '1%' }}>Ratings per Location</h3>

        {renderLineChart}
        <pre />
        <pre />
        <pre />
        <pre />
      </div>
      <div>
      <h3 style={{ display: 'flex', justifyContent: 'center', paddingTop: '5%', paddingBottom: '1%' }}>Locations based on Latitude/Longitude</h3>

        {renderScatterChart}
        <pre />
        <pre />
        <pre />
        <pre />
      </div>
      <div>
      <h3 style={{ display: 'flex', justifyContent: 'center', paddingTop: '5%', paddingBottom: '1%' }}>Number of Disorders Treated per Treatment</h3>

        {renderBarChart}
        <pre />
        <pre />
        <pre />
      </div>
    </div>
  );
}

export function Developer_visuals({non_profit_data, treatment_data, location_data}){
  var names = [];
  var numbers = [];
  const COLORS = ['orange', 'fuchsia', 'green', 'yellow', 'navy', 'green', 'maroon', 'red', 'purple', 'aqua', 'olive', 'silver', 'blue'];

  for (var i = 0; i < non_profit_data.length; i++) {
    names.push(non_profit_data[i].name);
    numbers.push(non_profit_data[i].value);
  }
 
  const [pieprops, setPieProps] = React.useState({
    labels: names,
    datasets: [
      {
        data: numbers,
        backgroundColor: COLORS
      }
    ]
  }, [non_profit_data]); 
  
  const options = {
    responsive: true
  };
  let [v1_data, setV1Data] = useState([]);
  let [v2_data, setV2Data] = useState([]);
  //let [v3_data, setV3Data] = useState("");
  useEffect(() => {
    fetch("https://www.centralvalleyfarmaid.me/api/GetAllLocations")
        .then(response => response.json())
        .then(json => {
            let crop_counter = {};
            
            // Iterate through each item in the 'data' array
            json.data.forEach(item => {
                // Iterate through each crop name in the 'crops' array
                item.crops.crops.forEach(crop => {
                    if (crop in crop_counter) {
                        crop_counter[crop] += 1;
                    } else {
                        crop_counter[crop] = 1;
                    }
                });
            });

            // Convert the crop_counter object into an array of objects
            let data = Object.entries(crop_counter).map(([crop, count]) => ({ crop, count }));
            
            // Update v1_data with the processed data
            setV1Data(data);
        });

        fetch("https://www.centralvalleyfarmaid.me/api/GetAllMarkets")
        .then(response => response.json())
        .then(json => {
            let city_counter = {};
            
            // Iterate through each item in the 'data' array
            json.data.forEach(item => {
                // Iterate through each crop name in the 'crops' array
                item.closest_locations.nearby_locations.forEach(city => {
                    if (city in city_counter) {
                      city_counter[city] += 1;
                    } else {
                      city_counter[city] = 1;
                    }
                });
            });

            // Convert the city_counter object into an array of objects
            let data = Object.entries(city_counter).map(([city, count]) => ({ city, count }));
            
            // Update v1_data with the processed data
            setV2Data(data);
        });
}, []);

  return (
    <div>
      <div>


        
      <h3 style={{ display: 'flex', justifyContent: 'center', paddingTop: '5%', paddingBottom: '1%' }}>Number of Locations Each Crop Can Grow In</h3>
      <BarGraph style={{ display: 'flex', justifyContent: 'center' }} data={v1_data}/>
      <h3 style={{ display: 'flex', justifyContent: 'center', paddingTop: '5%', paddingBottom: '1%' }}>Number of Farmer Markets' In Each Region</h3>
      <SpikeGraph style={{ display: 'flex', justifyContent: 'center' }} data={v2_data}/>


      </div>
      <div>
      <h3 style={{ display: 'flex', justifyContent: 'center', paddingTop: '5%', paddingBottom: '1%' }}>          Number of Non-profits per Category
</h3>

        <div className='ms-auto me-auto' style={{ width: '35%', height: 700 }}>
          <Pie data={pieprops}
              options={options}
              >
          </Pie> 
        </div>
      </div>
      <div>
      </div>
    </div>
  );
}

