import React from 'react';
import colebrown2552 from '../../assets/images/colebrown2552.webp';
import amorales159 from '../../assets/images/amorales159.webp';
import adonaytesfaye from '../../assets/images/adonaytesfaye.webp';
import omarazm123 from '../../assets/images/omarazm123.webp';
import ryanjpark03 from '../../assets/images/ryanjpark03.webp';


const usernameToImage = {
  colebrown2552,
  amorales159,
  adonaytesfaye,
  omarazm123,
  ryanjpark03,
};


function Team({ stat }) {
  const imageSrc = usernameToImage[stat.username];

  return (
    <div className='team-container'>
      <img
        className='img-fluid'
        src={imageSrc}
        alt=""
      />
    </div>
  );
}


export default Team;
