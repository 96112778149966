import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';
import { useLoadScript } from '@react-google-maps/api';
import 'bootstrap/dist/css/bootstrap.css';

import '../../../assets/css/style.css';
import '../../../assets/css/base.css';

import defaultImage from '../../../assets/images/tile_images/location_default.jpg';

export function LocationTile({ data, id, query }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDsLhIUWJBeG2p_YydtGFMKtbk-bB5IbLE",
  });

  const image = data.photo_info !== "" ? data.photo_info : defaultImage;

  const highlightText = (text) => {
    if (typeof text !== 'string') return text;
  
    if (!query) return text;
  
    const regex = new RegExp(`(${query})`, 'gi');
    return text.split(regex).map((part, index) => part.match(regex) ? <mark key={index}>{part}</mark> : part);
  };

  // Helper function to bold the text before the colon
  const boldBeforeColon = (text) => {
    return <span style={{ fontWeight: 600 }}>{text}</span>;
  };

  const center = useMemo(() => ({ lat: data.latitude, lng: data.longitude }), [data.latitude, data.longitude]);

  return (
    <div className="card tile">
      <div className="display-flex align-items-center">
        <img src={image} style={{ width: '100%', height: 300, objectFit: 'cover' }} alt="" />
      </div>
      <div className="card-body">
        <h5 className="card-title">{highlightText(data.name)}</h5>
      </div>
      <ul className="overviewTile list-group list-group-flush">
        <li className="list-group-item">{boldBeforeColon("Phone Number:")} {highlightText(data.phone_number)}</li>
        <li className="list-group-item">{boldBeforeColon("Address:")} {highlightText(data.address)}</li>
        <li className="list-group-item">{boldBeforeColon("Hours:")} {highlightText(data.hours)}</li>
        <li className="list-group-item">{boldBeforeColon("Latitude:")} {highlightText(data.latitude)}</li>
        <li className="list-group-item">{boldBeforeColon("Longitude:")} {highlightText(data.longitude)}</li>
      </ul>
      <div className="card-body">
        <Link to={`/locations/${id}`} className="btn btn-primary">More Info</Link>
      </div>
    </div>
  );
}
