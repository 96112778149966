import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const renderCustomAxisTick = (tickProps) => {
  const { x, y, payload } = tickProps;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">
        {payload.value}
      </text>
    </g>
  );
};
const renderNoLabel = () => {
  return null;
};


const BarGraph = ({ data }) => (
  <ResponsiveContainer width="100%" height={400}>
    <BarChart
      width={500}
      height={400}
      data={data}
      margin={{ top: 20, right: 30, left: 20, bottom: 70 }} // Increased bottom margin
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="crop" tick={renderCustomAxisTick} interval={0} />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="count" fill="#065535" barSize={30} />
    </BarChart>
  </ResponsiveContainer>
);

export default BarGraph;
